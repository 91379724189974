const API_BASE_URL = "__API_URL__";
import { navigate } from "svelte-routing";
export const getNewsletters = async () => {
    const res = await fetch(`${API_BASE_URL}/`);
    return await res.json();
};
export async function registerForNewsletter(listIds, email, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/register`, {
            method: "POST",
            body: JSON.stringify({ listIds, email, token }),
            mode: "cors",
            headers: {
                "Content-type": "application/json",
            },
        });
        if (response.status === 500) {
            navigate("/error", { replace: true });
        }
        return true;
    }
    catch (err) {
        return false;
    }
}
export async function unsubscribeForNewsletter({ email, token, listId, unsubscribedListId, }, grecaptchaToken) {
    try {
        const response = await fetch(`${API_BASE_URL}/unsubscribe`, {
            method: "POST",
            body: JSON.stringify({
                email,
                token,
                listId,
                unsubscribedListId,
                grecaptchaToken,
            }),
            mode: "cors",
            headers: {
                "Content-type": "application/json",
            },
        });
        if (response.status === 500) {
            navigate("/error", { replace: true });
        }
        return true;
    }
    catch (err) {
        return false;
    }
}
export async function verifyToken(token) {
    try {
        if (!token)
            navigate("/error", { replace: true });
        const response = await fetch(`${API_BASE_URL}/verify`, {
            method: "POST",
            body: JSON.stringify({ token }),
            mode: "cors",
            headers: {
                "Content-type": "application/json",
            },
        });
        if (response.status === 500) {
            navigate("/error", { replace: true });
        }
    }
    catch (err) {
        navigate("/error", { replace: true });
    }
}
