<script lang="ts">
  import { unsubscribeForNewsletter } from "../lib/api";
  import helixSdk from "../lib/helixTracking";
  const RECAPTCHA_KEY = "__RECAPTCHA_KEY__";

  helixSdk.trackViewEvent({
    product: {
      viewId: "avregistrering",
    },
  });

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token") ?? undefined;
  const email = urlParams.get("email") ?? undefined;
  const listId = urlParams.get("listId") ?? undefined;
  const unsubscribedListId = urlParams.get("unsubscribedListId") ?? undefined;

  function onloadCallback() {
    grecaptcha.ready(async () => {
      if ((email || token) && (listId || unsubscribedListId)) {
        // eslint-disable-line
        const grecaptchaToken = await grecaptcha.execute(RECAPTCHA_KEY, {
          action: "submit",
        }); // eslint-disable-line

        await unsubscribeForNewsletter(
          {
            email,
            token,
            listId,
            unsubscribedListId,
          },
          grecaptchaToken
        );
        helixSdk.trackClickEvent({
          name: "nyhetsbrev-unsubscribe",
          eventValues: {
            listId: listId ?? unsubscribedListId,
          },
        });

        // remove url params to prevent multiple requests on page reload
        window.history.replaceState(null, "", window.location.pathname);
      }
    });
  }
  window.onloadCallback = onloadCallback;
</script>

<svelte:head>
  <script
    src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=6LeZUJ0mAAAAAGofuB32K53y5hqHisg_84f6dQ1Y"
    async
    defer
  ></script>
</svelte:head>

<div class="svt-newsletter_content">
  <h1>
    <img
      class="svt-newsletter_logo"
      src="/images/svtlogo-black_v3.png"
      alt="SVT logotyp"
    />
    Nyhetsbrev
  </h1>

  <p>
    Din prenumeration har upphört och du kommer inte längre få några utskick
    från oss.
  </p>
</div>
